<template>

    <div class="card">
            
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="true"
                :showAddNew="false"
                title="IPD - Admission"
                @onClickCloseButton="onClickCloseButton"
            />
        </div>
       
        <div class="row">
            
            <PatientProfile 
                v-if="infoLoaddedStatus === 'done'"
                :doctors="admittedByUsers"
                v-model:profileId="formData.contact_profile_id"
            />

            <div v-if="infoLoaddedStatus === 'loading'" class="col-12 col-sm-3 col-md-4 col-lg-4">
                <Loader />
            </div>

            <div class="col-12 col-sm-8 col-md-8 col-lg-8">

                <div class="my-2 px-2">

                    <div class="row justify-content-between">

                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <label for="colFormLabel" class="col-form-label"><span class="required">*</span> Bed / Cabin: </label>
                            <v-select 
                                placeholder="Select bed/cabin" class="w-100"
                                :options="beds" label="name" 
                                :reduce="(name) => name.id"
                                v-model="formData.service_resource_id"
                                :selectable="bed => bed.service_general_status === 'open'"
                            >
                                <template v-slot:option="bed">
                                    {{ bed.name }}
                                    <span class="badge badge-light-dark font-small-3">{{ bed.service_general_status }}</span>
                                </template>
                            </v-select>
                        </div>
                        
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <label for="colFormLabel" class="col-form-label"><span class="required">*</span> Admission date</label>
                            <div class="input-group input-group-merge w-100">
                                <input
                                    v-model="formData.admission_date"
                                    name="datetime"
                                    type="datetime-local"
                                    class="form-control"
                                />
                            </div>
                        </div>

                    </div>
                    
                    <div class="row justify-content-between">

                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <label for="colFormLabel" class="col-form-label"><span class="required">*</span> Consultant: </label>
                            <v-select 
                                placeholder="Select consultant" class="w-100" 
                                :options="consultants" label="name"
                                :reduce="(name) => name.id" 
                                v-model="formData.human_resource_id"
                            />
                        </div>
                        
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <label for="colFormLabel" class="col-form-label">Department</label>
                            <div class="input-group input-group-merge w-100">
                                <v-select 
                                    placeholder="Select department" class="w-100" 
                                    :options="departments" label="name"
                                    :reduce="(name) => name.id" 
                                    v-model="formData.department_id"
                                />
                            </div>
                        </div>

                    </div>
                    
                    <div class="row justify-content-between">

                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <label for="colFormLabel" class="col-form-label"><span class="required">*</span> Admitted by: </label>
                            <v-select 
                                placeholder="Select admitted by" class="w-100" 
                                :options="admittedByUsers" label="name"
                                :reduce="(name) => name.id"
                                v-model="formData.admitted_by"
                            />
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <label for="colFormLabel" class="col-form-label">Nurse: </label>
                            <v-select 
                                placeholder="Select nurse" class="w-100" 
                                :options="nurses" label="name"
                                :reduce="(name) => name.id"
                                v-model="formData.nurse_id"
                            />
                        </div>
                    </div>

                </div>

                <div class="my-2 px-2">

                    <div class="row">

                        <div class="col-12 col-md-12">
                            <div class="bg-blue-light p-1">
                                <TitleButton 
                                    :showBtn="false"
                                    :showAddNew="false"
                                    title="Admission Notes"
                                />
                            </div>
                        </div>

                        <div v-for="(note, index) in noteTypes" :key="note.vaule" class="col-12 col-md-12 note-section">
                            <AdmissionNote
                                :note="note"
                                :index="index"
                                @openModal="openModal"
                            />
                        </div>

                    </div>
                </div>

            </div>

            <div class="col-12 text-center mb-3">
                <hr>
                <button :disabled="loading" @click="submit" type="submit" class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                    <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <span v-if="!loading">
                        <i class="fas fa-plus"></i>
                    </span>
                    
                    {{ loading ? 'Please wait...' : 'Save' }}
                </button>
                
            </div>

        </div>

        <AddNoteModal
            v-if="store.state.isModalOpen"
            :note="singleNote"
        /> 

    </div>

</template>

<script setup>

    import TitleButton from '@/components/atom/TitleButton';
    import { inject, onMounted, reactive, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { getCurrentDateTime, json_string_parse } from '@/services/utils/global.js'
    import { useStore } from 'vuex';
    import handleHospital from '@/services/modules/hospital'
    import AdmissionNote from '@/components/molecule/company/hospital/note/Note.vue';
    import AddNoteModal from '@/components/molecule/company/hospital/note/AddNoteModal.vue';
    import PatientProfile from '@/components/molecule/company/hospital/ipd-register/PatientProfile.vue'
    import handleContact from '@/services/modules/contact'
    import Loader from "@/components/atom/LoaderComponent";

    const $router = useRouter();
    const $route = useRoute();
    const store = useStore();
    const singleNote = ref(null);
    const loading = ref(false);
    const loader = ref(false);

    const consultants = reactive([]);
    const beds = reactive([]);
    const admittedByUsers = reactive([]);
    const nurses = reactive([]);
    const departments = reactive([]);
    const noteTypes = reactive([]);
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');
    const infoLoaddedStatus = ref('loading');
    const {
        fetchServiceList,
        fetchDepartList,
        fetchAdmissionNoteList,
        storeRegistration,
        updateRegistration,
        fetchSingleNote
    } = handleHospital()

    const { fetchContactProfiles } = handleContact()

    const formData = ref({
        company_id: $route.params.companyId,
        human_resource_id: null,
        service_resource_id: null,
        contact_profile_id: null,
        admitted_by: null,
        nurse_id: null,
        admission_date: getCurrentDateTime(),
        department_id: null,
        notes: [], 
        status: 'active',
    })

    const submit = async() => {
        const admissionId = $route.params.admissionId;
        formData.value.notes = formatNotes();
        loading.value = true;
        const regMethod = admissionId ? updateRegistration : storeRegistration;
        await regMethod(formData.value, admissionId).then((res) => {
            loading.value = false
            if (!res.status){
                showError(res.message)
                return;
            }
            onClickCloseButton();
            return showSuccess(res.message);
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            loading.value = false
        })
    }

    const formatNotes = () => {
       return noteTypes.filter(item => item.selectedNotes.length)
        .map(item => {
            return {
                id: item.id ? item.id : '',
                type: item.value,
                note: JSON.stringify(item.selectedNotes),
                new_notes: item.newNotesByDoctor,
            }
        })
    }

    const openModal = ({note, index}) => {
        singleNote.value = null;
        singleNote.value = {
            index: index,
            name: note.name,
            value: note.value,
            selectedNotes: note.selectedNotes,
            preAddedNotes: json_string_parse(note.preAddedNotes),
            noteMasters: note.noteMasters, 
            newNotesByDoctor: note.newNotesByDoctor,
        }
        
        if(singleNote.value){
            store.state.isModalOpen = true;
        }
    }
    
    const onClickCloseButton = () => {
        $router.push({name: 'ipd-register',
            params: {
                companyId: $route.params.companyId,
                moduleId: $route.params.moduleId,
                menuId: $route.params.menuId,
                pageId: $route.params.pageId,
            },
            query: $route.query,
        })
    }

    function getQuery() {
        let query = `?company_id=${$route.params.companyId}&search_type=all`;
        return query;
    }

    const getSingleIpdRegister = (query, id) => {
        if(!id){
            infoLoaddedStatus.value = 'done';
            return;
        };
        fetchSingleNote(query, id).then((res) => {
            if (!res.status) return showError(res.message);
            formData.value = res.data;
            setNoteData(res.data.notes)
            infoLoaddedStatus.value = 'done';
        })
    }

    const setNoteData = (notes) => {
        for(let note of notes){
            const index = noteTypes.findIndex(item => item.value === note.type);
            if(index < 0) continue;
            noteTypes[index].id = note.id;
            noteTypes[index].selectedNotes = note.note;
        }
    }


    onMounted( async () => {
        const query = getQuery();
        const admissionId = $route.params.admissionId;
        
        loader.value = true;
        await Promise.all([
            fetchServiceList(query, 'human_resources').then((res) => {
                if(res.status) consultants.push(...res.data)
            }),
            fetchServiceList(query, 'places').then((res) => {
                if(res.status) beds.push(...res.data)
            }),
            fetchContactProfiles(query + `&type=nurse`).then((res) => {
                if(res.status) nurses.push(...res.data)
            }),
            fetchContactProfiles(query + `&type=doctor`).then((res) => {
                if(res.status) admittedByUsers.push(...res.data)
            }),
            fetchDepartList(query).then((res) => {
                if(res.status) departments.push(...res.data)
            }),
            fetchAdmissionNoteList(query).then((res) => {
                if(res.status) noteTypes.push(...res.data)
            })
        ]).then(() => {
            getSingleIpdRegister(query, admissionId),
            loader.value = false;
        }).catch((err) => {
            loader.value = false
        });
    })

</script>

<style scoped>
.note-section {
    margin-top: 2%;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    width: 96%;
    margin-left: 2%;
}
</style>